
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'BlogAcfBlocks',
  props: {
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      acfBlocks: 'wp/getAcfBlocks',
    }),
  },
})
